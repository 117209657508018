<template>
	<div class="outer-wrapper full-height">
		<div id="top-nav"></div>
		<div class="fixed-top outer-wrapper">
			<router-link 
				v-if="this.$route.query.trip_item_id && this.$route.query.trip_id"
				:to="{ name: 'Saved Flights Details', params: { trip_id: this.$route.query.trip_id, trip_item_id: this.$route.query.trip_item_id }}"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<router-link 
				v-else-if="this.$route.query.trip_id"
				:to="{ name: 'Trip', params: { trip_id: this.$route.query.trip_id }}"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<router-link 
				v-else
				:to="{ name: 'Home' }"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<div class="trip-type-holder">
				<span class="title">Flights Search</span>
			</div>
		</div>
		<div class="inner-wrapper">
			<locationDropdown 
				v-model="flightsSearch.searchValues.startLocation"
			/>
			<locationDropdown 
				v-model="flightsSearch.searchValues.endLocation"
				class="return"
			/>
			<button class="button" v-on:click="submit()" :class="{ disabled: !checkForm }">Continue</button>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import locationDropdown from '@/components/locationDropdown.vue';
	import router from '@/router';

	export default {
		name: 'flightsSearchLocations',
		data() {
			return {
				errors: new Map(),
			}
		},
		components: {
			locationDropdown,
		},
		computed: {
			...mapState({
				flightsSearch: state => state.flightsSearch,
			}),
			checkForm() {
				this.errors = new Map();
				let locationErrors = [];
				if(this.flightsSearch.searchValues.startLocation == null || this.flightsSearch.searchValues.startLocation == "") {
					locationErrors.push('You must select a start location');
				}
				if(this.flightsSearch.searchValues.endLocation == null || this.flightsSearch.searchValues.endLocation == "") {
					locationErrors.push('You must select a destination');
				}
				if(locationErrors.length > 0) {
					this.errors.set('locations', locationErrors);
				}
				if (!this.errors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'flightsSearch/mapQueryParams',
			}),
			submit() {
				if(this.checkForm) {
					router.push({
						name: 'Flights Dates',
					})
				}
			},
		},
		created () {
			this.flightsSearch.shouldSearch = true;

			this.mapQueryParams();
		},
	}
</script>

<style scoped>
	.button {
		display: block;
		margin: 0 auto;
	}
	.trip-type-holder {
		position: relative;
		text-align: center;
	}
	.title {
		width: 155px;
		display: inline-block;
		color: #118AB2;
		font-weight: bold;
		font-size: 20px;
	}
</style>